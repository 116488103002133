'use client';

import React, { useEffect, useState, useCallback, useRef } from 'react';
import ProductListing from './ProductListing';
import Link from 'react-router-dom';
import { IProduct } from '../schema/Product';
import axios from 'axios';

interface ProductReelProps {
  title: any; 
  subtitle?: string;
  href?: string;
  textColor: string;
}

const ProductReel = ({ title, subtitle, href, textColor }: ProductReelProps) => {
  const [approvedProducts, setApprovedProducts] = useState<IProduct[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(0); // Track total pages
  const loadingRef = useRef<HTMLDivElement | null>(null); // Ref for loading indicator
  const productsPerPage = 16; // Number of products per page
  const fetchProducts = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/products/approved`, {
        withCredentials: true
      });
      if (response.data && Array.isArray(response.data.products)) {
        setApprovedProducts(response.data.products); // Fetch all products
        setTotalPages(Math.ceil(response.data.products.length / productsPerPage)); // Calculate total pages
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error: any) {
      console.error('Error fetching products:', error);
      setError(error.message);
    }
  }, []); // No dependency on currentPage

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && currentPage < totalPages) {
        setCurrentPage(prev => prev + 1); // Load next page
      }
    });

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [loadingRef, currentPage, totalPages]);

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="product-reel-container">
      <section className="py-12">
        <div className="md:flex md:items-center md:justify-between mb-4">
          <div className="max-w-2xl px-4 lg:max-w-4xl lg:px-0">
            {title && (
              <h1 className="text-2xl font-bold sm:text-3xl" style={{ color: textColor, fontFamily: 'Helvetica' }}>
                {title}
              </h1>
            )}
            {subtitle && (
              <p className="mt-2 text-sm text-muted-foreground" style={{ color: textColor, fontFamily: 'Helvetica' }}>
                {subtitle}
              </p>
            )}
          </div>
          {href && (
            <a
              href={href}
              className="hidden text-sm font-medium text-gray hover:text-gray md:block"
              style={{ color: textColor, fontFamily: 'Helvetica' }}
            >
              Shop now <span aria-hidden="true">&rarr;</span>
            </a>
          )}
        </div>

        <div className="relative">
          <div className="mt-6 flex items-center w-full">
            <div className="w-full grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-10 lg:gap-x-8">
              {Array.isArray(approvedProducts) && approvedProducts.map((product: IProduct, i: number) => (
                <ProductListing key={`product-${i}`} product={product} index={i} textColor={textColor} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductReel;